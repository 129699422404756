@import '/src/breakpoints.scss';

.footer {
  text-align: center;
  font-size: 16px;
  padding-bottom: 15px;
  @include medium {
    font-size: 26px;
    padding-bottom: 50px;
  }
}

.footer p {
  margin: auto;
  line-height: 1.8;
  margin-bottom: 20px;
  max-width: 320px;
  @include small {
    max-width: 320px;
  }
  @include medium {
    max-width: 520px;
  }
  @include large {
    max-width: 100%;
  }
}
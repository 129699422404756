*,
*::before,
*::after {
  box-sizing: border-box;
}

/* :root {
  --background: white;
  --text-primary: #172d4e;
  --text-secondary: white;
  --icon-primary: #172d4e;
  --accent: #ffcc40;
  --button-hover: #172d4e;
  --transition-speed: 0.5s;
}
[data-theme="dark"] {
  --background: black;
  --text-primary: white;
  --text-secondary: black;
  --icon-primary: #ffcc40;
  --accent: black;
  --button-hover: black;
  background: black;
} */

:root {
  --background: white;
  --text-primary: black;
  --text-secondary: white;
  --icon-primary: black;
  --accent: #eb0045;
  --button-hover: black;
  --transition-speed: 0.5s;
}
[data-theme="dark"] {
  --background: black;
  --text-primary: white;
  --text-secondary: black;
  --icon-primary: #eb0045;
  --accent: black;
  --button-hover: black;
  background: black;
}

body {
  margin: 0;
  padding: 0;
}

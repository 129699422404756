@import '/src/breakpoints.scss';

.NextStep {
  position: relative;
  text-align: center;
  margin-bottom: 60px;
  color: var(--text-primary);
  text-decoration: none;
  &:first-of-type {
    margin-top: 25px;
  }
  @include medium {
    margin-bottom: 70px;
    &:nth-of-type(2)
     {
      margin-top: 25px;
    }
  }
  @include large {
    &:nth-of-type(3)
     {
      margin-top: 25px;
    }
  }
}
.inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 5px solid #eb0045;
  border-radius: 25px;
  padding: 25px 20px 20px;
  height: 100%;
}

.title {
  font-size: 35px;
  font-weight: 700;
  margin: 0;
  transition: all var(--transition-speed);
}

.description {
  font-size: 26px;
  font-weight: 400;
  margin: 0;
  transition: all var(--transition-speed);
}

.half-circle {
  position: absolute;
  top: -33px;
  left: calc(50% - 38px);
  display: block;
  width: 76px;
  height: 38px;
  border-top-left-radius: 110px;
  border-top-right-radius: 110px;
  border: 5px solid #eb0045;
  border-bottom: 0;
  background: var(--background);
  transition: all var(--transition-speed);
}

.logo {
  height: 100;
  width: 100;
}

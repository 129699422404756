@import "/src/breakpoints.scss";

.socialLinks {
  border-top: 3px solid var(--text-primary);
  border-bottom: 3px solid var(--text-primary);
  margin: 30px 0 40px 0;
  padding: 30px 0;
  display: flex;
  justify-content: center;
  @include medium {
    margin: 30px 0 40px 0;
    padding: 60px 0;
  }
}

.socialLinks a {
  border: 2px solid var(--text-primary);
  padding: 10px;
  border-radius: 50%;
  margin: 0 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all var(--transition-speed);
  @include medium {
    padding: 20px;
  }
  &:hover {
    background: var(--text-primary);
    svg {
      fill: var(--text-secondary);
    }
  }
}

.socialLinks svg {
  height: 30px;
  width: 30px;
  fill: var(--text-primary);
  @include medium {
    height: 50px;
    width: 50px;
  }
}

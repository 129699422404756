// bootstrap breakpoints
$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xl-min: 1200px;

// Small devices
@mixin small {
  @media (min-width: #{$screen-sm-min}) {
      @content;
  }
}

// Medium devices
@mixin medium {
  @media (min-width: #{$screen-md-min}) {
      @content;
  }
}

// Large devices
@mixin large {
  @media (min-width: #{$screen-lg-min}) {
      @content;
  }
}

// Extra large devices
@mixin xlarge {
  @media (min-width: #{$screen-xl-min}) {
      @content;
  }
}
@import '/src/breakpoints.scss';

.logo {
  text-align: center;
  margin-bottom: 20px;
  @include medium {
    margin-bottom: 50px;
  }
}

.logo svg {
  fill: var(--text-primary);
  width: 100%;
  max-width: 220px;
  transition: all var(--transition-speed);
  @include medium {
    max-width: 330px;
  }
}
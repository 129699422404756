@import '/src/breakpoints.scss';

.SectionTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all var(--transition-speed);
  margin-bottom: 30px;
  @include medium {
    margin-bottom: 50px;
  }
}

.SectionTitle h2 {
  text-align: center;
  font-size: 26px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0 15px;
  white-space: nowrap;
  @include medium {
    font-size: 50px;
    letter-spacing: 2px;
  }
}

.SectionTitle:before,
.SectionTitle:after {
  content: '';
  display: block;
  width: 100%;
  height: 3px;
  background: var(--text-primary);
  transition: all var(--transition-speed);
}

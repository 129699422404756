@import "/src/breakpoints.scss";

.pill {
  display: grid;
  grid-template-columns: auto 1fr 1fr;
  grid-template-rows: auto auto;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "icon content content"
    "icon button button";
  align-items: center;
  background: var(--accent);
  margin-bottom: 30px;
  border: 5px solid #eb0045;
  border-radius: 50px;
  min-height: 100px;
  transition: var(--transition-speed);
  padding: 10px 15px 10px 10px;
  &:last-of-type {
    margin-bottom: 40px;
  }
  @include medium {
    display: flex;
    flex-direction: row;
    padding: 0;
    margin-bottom: 40px;
  }
}

.icon {
  grid-area: icon;
}

.content {
  flex: 1;
  grid-area: content;
  color: white;
}

.title {
  font-size: 25px;
  margin: 0 0 5px;
  padding-right: 20px;
  @include medium {
    font-size: 35px;
    margin: 0 0 0px;
  }
}

.description {
  font-size: 20px;
  margin: 0 0 15px;
  @include medium {
    font-size: 26px;
    margin: 0;
    padding-right: 20px;
  }
}

.buttonWrapper {
  grid-area: button;
  display: flex;
}
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 20px;
  min-height: 20px;
  min-width: 150px;
  width: auto;
  border-radius: 28px;
  color: var(--text-secondary);
  background: var(--text-primary);
  text-decoration: none;
  padding: 10px 20px;
  transition: all var(--transition-speed);
  @include medium {
    font-size: 26px;
    margin-right: 15px;
    min-height: 56px;
    max-width: 100%;
  }
}

.button:hover {
  color: var(--icon-primary);
  background: white;
  transition: all 0.15s;
}

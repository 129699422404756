@import '/src/breakpoints.scss';

.icon {
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  fill: var(--icon-primary);
  color: var(--icon-primary);
  transition: all var(--transition-speed);
  overflow: hidden;
}

.icon.nextStep {
  position: absolute;
  left: calc(50% - 22px);
  top: -18px;
}

.icon.signUp {
  width: 65px;
  height: 65px;
  padding: 15px;
  margin-right: 15px;
  background: var(--background);
  border-radius: 50%;
  @include medium {
    width: 90px;
    height: 90px;
    padding: 20px;
  }
}

.svg {
  height: 40px;
}